import React from "react";
import logoImg from "../../images/logo_mono.svg";
import linkedInImg from "../../images/linkedin.svg";
import facebookImg from "../../images/facebook_mono.svg";
import twitterImg from "../../images/twitter_mono.svg";
import githubImg from "../../images/github_mono.svg";
import emailImg from "../../images/email.svg";
import {
  getCompetencyMatrixUrl,
  getCookieUrl,
  getMondayAppsDevelopmentUrl,
  getMondayAppsEULAUrl,
  getProductDevelopmentUrl,
  getServicesUrl,
} from "../../utils/urls";

import { Link } from "../Link";
import {
  getHomeUrl,
  getTeamExtensionUrl,
  getBlogUrl,
  getPrivacyUrl,
  getTermsUrl,
  getCaseStudiesUrl,
  getVacanciesUrl,
  getAboutUsUrl,
} from "../../utils/urls";
import { ClutchReviews } from "../Awards/ClutchReviews";
import { useShowCookiePreferences } from "./CookieContainer";
import * as styles from "./Footer.module.scss";
import { Container } from "../Section";
import { AwardsList } from "../Awards";
import cn from "classnames";

export const Footer = () => {
  const showCookiePreferences = useShowCookiePreferences();

  return (
    <footer className={styles.footer}>
      <Container>
        <div className={cn(styles.row, styles.topRow)}>
          <div className={styles.col}>
            <Link className={styles.brand} to={getHomeUrl()}>
              <img alt="LeanyLabs" src={logoImg} />
            </Link>
            <div className={styles.brandText}>
              We deliver custom software solutions so businesses can focus on
              what they do best.
            </div>
          </div>

          <ul className={styles.serviceLinks}>
            <li>
              <Link to={getServicesUrl()}>Services</Link>
            </li>
            <li>
              <Link to={getProductDevelopmentUrl()}>Product Development</Link>
            </li>
            <li>
              <Link to={getMondayAppsDevelopmentUrl()}>
                monday.com Apps Development
              </Link>
            </li>
            <li>
              <Link to={getTeamExtensionUrl()}>Team Extension</Link>
            </li>
          </ul>

          <ul className={styles.links}>
            <li>
              <Link to={getAboutUsUrl()}>About LeanyLabs</Link>
            </li>
            <li>
              <Link to={getCompetencyMatrixUrl()}>Competency Matrix</Link>
            </li>
            <li>
              <Link to={getBlogUrl()}>Blog</Link>
            </li>
            <li>
              <Link to={getCaseStudiesUrl()}>Case Studies</Link>
            </li>
            <li>
              <Link noIndex to={getVacanciesUrl()}>
                Join LeanyLabs
              </Link>
            </li>
          </ul>

          <ul className={styles.links}>
            <li>
              <Link to={getTermsUrl()}>Terms of Use</Link>
            </li>
            <li>
              <Link to={getMondayAppsEULAUrl()}>monday.com Apps EULA</Link>
            </li>
            <li>
              <Link to={getPrivacyUrl()}>Privacy Policy</Link>
            </li>
            <li>
              <Link to={getCookieUrl()}>Cookie Policy</Link>
            </li>
            <li>
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  showCookiePreferences();
                }}
              >
                Cookie Preferences
              </Link>
            </li>
          </ul>
        </div>

        <div className={cn(styles.row, styles.middleRow)}>
          <div className={styles.socials}>
            <ul>
              <li>
                <Link to="https://www.linkedin.com/company/leanylabs">
                  <img alt="LinkedIn" title="LinkedIn" src={linkedInImg} />
                </Link>
              </li>
              <li>
                <Link to="https://github.com/LeanyLabs">
                  <img alt="GitHub" title="GitHub" src={githubImg} />
                </Link>
              </li>
              <li>
                <Link to="https://twitter.com/leanylabs">
                  <img alt="Twitter" title="Twitter" src={twitterImg} />
                </Link>
              </li>
              <li>
                <Link to="https://www.facebook.com/LeanyLab">
                  <img alt="Facebook" title="Facebook" src={facebookImg} />
                </Link>
              </li>
            </ul>
            <Link
              className={styles.email}
              ext
              aria-label="email"
              to="mailto:hi@leanylabs.com?Subject=Hi%20LeanyLabs"
            >
              <img alt="Email" title="Email" src={emailImg} />
              hi@leanylabs.com
            </Link>
          </div>
          <div className={styles.awards}>
            <AwardsList center />
          </div>
          <ClutchReviews type="compact" light />
        </div>

        <div className={styles.bottomRow}>
          <div className={styles.address}>
            <p>Estonia, Tallinn 15551, Sepapaja tn 6</p>
            <p>Ukraine, Lviv 79000, Sadova 2a</p>
          </div>
          <div>
            <p>{new Date().getFullYear()} LeanyLabs OÜ. All rights reserved.</p>
          </div>
        </div>
      </Container>
    </footer>
  );
};
